.sums-color {
    color: #1ab394;
}

.logo {
    width: 100px;
    height: 50px;
    object-fit: contain;
}

.seat-standard {
    color: white;
    background-color: #43aa8b;
  }

.seat-vip {
    color: white;
    background-color: #da3c08;
  }

.seat-default {
    color: white;
    background-color: red;
    opacity: 0.2;
}
